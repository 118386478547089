.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.custom-card {
  margin: 50px 0px;
  padding: 20px 10px;
  justify-content: center;
  height: fit-content;
  width: 100%;
  border-radius: 10px;
  box-shadow: #0f9b51 0px 1px 2px 0px, #0f9b51 0px 1px 3px 1px;

  /* box-shadow: rgb(0 0 0 / 25%) 0px 54px 55px, rgb(0 0 0 / 12%) 0px -12px 30px, rgb(0 0 0 / 12%) 0px 4px 6px, rgb(0 0 0 / 17%) 0px 12px 13px, rgb(0 0 0 / 9%) 0px -3px 5px; */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.toast-class {
  z-index: 9999999999999999 !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.sortable-handler {
  touch-action: none;
}

.lkgapbetweencontainer {
  margin-bottom: 55px;
}

.react-datepicker__header__dropdown.react-datepicker__header__dropdown--select {
  position: absolute;
  top: 9px;
  left: 43px;
}

.react-datepicker__current-month.react-datepicker__current-month--hasYearDropdown.react-datepicker__current-month--hasMonthDropdown {
  visibility: hidden;
}

.gotourlicon {
  cursor: pointer;
}

.green_btn{
  background-color: #0f9b51;
  display: flex;
  align-items: center;
  padding: 7px 23px;
  border: 3px solid #0f9b51 !important;
  border-radius: 10px;
  font-size: 1.4rem;
  font-weight: 700;
  color: white;
}
.green_btn:hover {
  background-color: #d0ebdc;
  color: black;
}
.ril__inner{
  margin-top: 65px;
  z-index: 999999;
  margin-bottom:65px;
}
.ril__caption{
  z-index: 999999 !important;
}
.ril__toolbar{
  z-index: 999999 !important;

  /* top:88px!important; */
  /* background-color: transparent!important; */
}
.ril__toolbarRightSide{
  display: flex;
  align-items: center;
}

.ril-prev-button,.ril-next-button{
  z-index: 99999999999;
}

@media only screen and (max-width: 766px) and (min-width: 758px) {
  .ril__toolbar {
    top: 89px!important;
  }
 
}
@media screen and (min-width: 768px) and (max-width: 776px) {
  .ril__toolbar {
    top: 132px!important;
  }
 
}
@media screen and (min-width: 777px) and (max-width: 794px) {
  .ril__toolbar {
    top: 131px!important;
    /* top: 171px; */
  }
 
}

@media screen and (min-width: 792px) and (max-width: 1097px) {
  .ril__toolbar {
    top: 93px!important;
  }
  
}
#lending_vakil_spinner{
  color:#f8c94b!important
}
.link--url{
  font-size: large;
}
.ril-image-current.ril__image{
  object-fit: contain;
}
.libra_lightbox .ril__toolbar{
  top:70px!important;
}
a{
  text-decoration: none;
}

.__default_green_btn {
  display: flex;
  align-items: center;
  padding: 18px 14px;
  border: 3px solid #0f9b51;
  border-radius: 10px;
  height: 36px;
  margin-right: 14px;
  color: var(--navColor);
  font-size: 1.4rem;
  font-weight: 700;
  background-color: #0f9b51;
  color: white;
}
.__default_green_btn:disabled,
.__default_green_btn[disabled]{
 opacity: 0.5;
 cursor: not-allowed;
}

.__default_green_btn:not(:disabled):not([disabled]):hover {
  background-color: #d0ebdc;
  color: black;
}

.ReactModal__Overlay--after-open{
  z-index: 999999!important;
}
.__report_name_with_index{
  color: white;
    display: flex;
    align-items: center;
    font-size: 14px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
}
._hide_arrow{
  opacity: 1 !important;
}
._hide_arrow .react-tooltip-arrow{
  display: none!important;
}
.react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^=top] .react-datepicker__triangle::after, .react-datepicker-popper[data-placement^=bottom] .react-datepicker__triangle::after{
  left: -156px !important;
}
._parent_drop_d_e{
  border-radius: unset;
  display: flex;
    align-items: center;
    justify-content: center;
    width: 31.39px;
}
.download_btn_h{
  height: 34px;
  border-radius: unset;
}
._common_btn_no_outline_border{
  outline: none !important;
  border: none !important;
}

.split-dropdown {
  position: relative;
}

.split-dropdown .dropdown-menu {
  width: 100%;
  position: absolute;
  inset: auto 0 auto 0; /* This ensures the menu spans the full width */
}

/* Break Point */
/* xs: 'screen and (max-width: 599px)',
    sm: 'screen and (min-width: 600px) and (max-width: 959px)',
    md: 'screen and (min-width: 960px) and (max-width: 1279px)',
    lg: 'screen and (min-width: 1280px) and (max-width: 1919px)',
    xl: 'screen and (min-width: 1920px) and (max-width: 5000px)', */